@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Revue-Bold';
  src: url('/public/Revue-Bold.otf') format('truetype');
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  @apply bg-Bar;
  border: 3px solid #eef3f7;
  border-radius: 7px;
}

::-webkit-scrollbar-track {
  @apply bg-Track;
}

::selection {
  @apply text-[#f0fdfa] bg-cyan-600;
}